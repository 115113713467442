/* eslint-disable import/no-unresolved */
import styled from '@emotion/styled'
import { transparentize } from 'polished'
import * as React from 'react'
import EmailIcon from '../images/svgs/Email.svg'
import FacebookIcon from '../images/svgs/Facebook.svg'
import MapIcon from '../images/svgs/Map.svg'
import PhoneIcon from '../images/svgs/Phone.svg'
import WebIcon from '../images/svgs/Web.svg'
import { breakpointMq, getEmSize, hoverMq } from '../styles/mixins'
import { breakpoints, colors, dimensions } from '../styles/variables'

export enum ContactType {
  web = 'Go to web site',
  facebook = 'Go to our Facebook page',
  phone = 'Call us',
  email = 'Email us',
  map = 'Map location'
}

const buttonSize = `${getEmSize(52)}rem`
const buttonSizeSmall = `${getEmSize(40)}rem`
const buttonSizeMedium = `${getEmSize(48)}rem`

const hoverStyles = {
  'svg path': {
    fill: colors.white
  },
  backgroundColor: colors.brand
}
const StyledAnchor = styled.a({
  width: buttonSize,
  height: buttonSize,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  border: 'none',
  backgroundColor: colors.white,
  marginRight: `${dimensions.spacing[300]}rem`,
  boxShadow: '0px 0px 24px rgba(89, 19, 20, 0.1)',
  WebkitTapHighlightColor: 'transparent',
  svg: {
    height: `${getEmSize(24)}rem`,
    g: {
      opacity: 1
    },
    path: {
      transition: 'all 200ms ease',
      fill: transparentize(0.7, colors.black)
    }
  },
  '&:active': {
    ...hoverStyles
  },
  [hoverMq]: {
    '&:hover': {
      ...hoverStyles
    }
  },
  transition: 'all 400ms ease',
  [breakpointMq(breakpoints.xxs, breakpoints.sm)]: {
    width: buttonSizeSmall,
    height: buttonSizeSmall,
    marginRight: `${dimensions.spacing[200]}rem`,
    svg: {
      height: `${getEmSize(18)}rem`
    }
  }
})

const Icon: React.FunctionComponent<{ type: ContactType }> = ({ type }) => {
  switch (type) {
    case ContactType.email:
      return <EmailIcon />
    case ContactType.facebook:
      return <FacebookIcon />
    case ContactType.map:
      return <MapIcon />
    case ContactType.phone:
      return <PhoneIcon />
    case ContactType.web:
      return <WebIcon />
    default:
      return <EmailIcon />
  }
}

const getUrl = (type: ContactType) => {
  switch (type) {
    case ContactType.email:
      return 'mailto:cacc@cacc.org.nz'
    case ContactType.facebook:
      return 'https://www.facebook.com/caccnz/'
    case ContactType.map:
      return 'https://www.google.co.nz/maps/place/Chinese+Alliance+Christian+Church/@-36.9143723,174.90007,17z/data=!3m1!4b1!4m5!3m4!1s0x6d0d4b74224f9d7b:0x42d919b3e340024d!8m2!3d-36.9143723!4d174.9022587'
    case ContactType.phone:
      return 'tel:+64-91-576-7070'
    case ContactType.web:
      return 'http://cacc.org.nz/'
    default:
      return ''
  }
}

const ContactButton: React.FunctionComponent<{ type: ContactType; className?: string }> = ({ type, className }) => (
  <StyledAnchor className={className} href={getUrl(type)} aria-label={type} target="_blank" title={type}>
    <Icon type={type} />
  </StyledAnchor>
)

export default ContactButton
