import { css, keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import * as React from 'react'
import { breakpointMq, getEmSize } from '../styles/mixins'
import { breakpoints, colors, dimensions, fonts } from '../styles/variables'
import { AnniversaryLogo } from './AnniversaryLogo'
import { VSpacer } from './Spacer'

const StyledSubtitle = styled.div({
  fontWeight: 700,
  fontStyle: 'italic',
  color: colors.brandLight,
  fontSize: `${dimensions.headingSizes.h3}rem`,
  [breakpointMq(breakpoints.xxs, breakpoints.md)]: {
    fontSize: `1rem`
  }
})

const StyledTitle = styled.div({
  display: 'inline-block',
  fontFamily: fonts.banner,
  fontSize: `${getEmSize(72)}rem`,
  color: colors.brand,
  position: 'relative',
  left: `${getEmSize(170)}rem`,
  top: `${getEmSize(-16)}`,
  paddingBottom: '48px',
  span: {
    display: 'inline-block'
  },
  [breakpointMq(breakpoints.xxs, breakpoints.xs)]: {
    fontSize: `${getEmSize(34)}rem`,
    left: `${getEmSize(82)}rem`
  },
  [breakpointMq(breakpoints.xs, breakpoints.md)]: {
    fontSize: `${getEmSize(48)}rem`,
    left: `${getEmSize(76)}rem`
  }
})

const StyledAnniversaryLogo = styled(AnniversaryLogo)({
  [breakpointMq(breakpoints.xxs, breakpoints.xs)]: {
    width: `${getEmSize(125)}rem !important`
  },
  [breakpointMq(breakpoints.xs, breakpoints.md)]: {
    width: `${getEmSize(145)}rem !important`
  },
  width: `${getEmSize(277)}rem !important`
})

const createCharacterAnimation = (numberOfCharacters: number, initialDelay: number, delayPerCharacter: number, duration: number) => {
  const cssObject = Array.from(Array(numberOfCharacters)).reduce<{}>((acc, _val, index) => {
    return {
      ...acc,
      [`span:nth-of-type(${index + 1})`]: {
        animation: `showHide ${duration}ms ${initialDelay + delayPerCharacter * index}ms ease-in`,
        animationFillMode: 'backwards'
      }
    }
  }, {})

  return css(cssObject)
}

const titleAnimation = css([
  {
    '@keyframes showHide': {
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      }
    },
    '.title': createCharacterAnimation(11, 1300, 120, 350)
  }
])

const subtitleAnimation = keyframes({
  from: { transform: 'translate3d(30px, 0, 0)', opacity: 0 },
  to: { transform: 'translate3d(0, 0, 0 )', opacity: 1 }
})

const logoAnimation = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 }
})

const animations = css([
  titleAnimation,
  {
    '.subtitle': {
      animation: `${subtitleAnimation} 1000ms ease`
    },
    '.logo': {
      animation: `${logoAnimation} 1200ms 500ms ease-in`,
      animationFillMode: 'backwards'
    }
  }
])

const LogoContainer = () => (
  <div
    css={[
      animations,
      {
        marginBottom: `${dimensions.spacing[400]}rem`,
        position: 'relative',
        [breakpointMq(breakpoints.xxs, breakpoints.xs)]: {
          left: `-${getEmSize(30)}rem`,
          marginBottom: `${dimensions.spacing[300]}rem`
        },
        [breakpointMq(breakpoints.xs, breakpoints.md)]: {
          left: `-${getEmSize(30)}rem`,
          marginBottom: `${dimensions.spacing[300]}rem`
        }
      }
    ]}
  >
    <StyledSubtitle className="subtitle">— Celebrating our</StyledSubtitle>
    <VSpacer size="200" />
    <StyledAnniversaryLogo className="logo" />
    <StyledTitle className="title">
      <span>A</span>
      <span>n</span>
      <span>n</span>
      <span>i</span>
      <span>v</span>
      <span>e</span>
      <span>r</span>
      <span>s</span>
      <span>a</span>
      <span>r</span>
      <span>y</span>
    </StyledTitle>
  </div>
)

export default LogoContainer
