import * as React from 'react'

import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import Background from '../components/Background'
import { breakpoints, dimensions, heights, widths } from '../styles/variables'
import { HSpacer } from '../components/Spacer'
import { breakpointMq, getEmSize } from '../styles/mixins'
import { PrimaryButton, SecondaryButton } from '../components/Buttons'
import Footer from '../components/Footer'
import LogoContainer from '../components/AnimatedLogoGroup'

const StyledPage = styled(Page)({
  [breakpointMq(breakpoints.xxs, breakpoints.md)]: {
    padding: `0 ${dimensions.spacing[200]}rem ${heights.footerMobile}rem ${dimensions.spacing[200]}rem`
  },
  [breakpointMq(breakpoints.md, breakpoints.lg)]: {
    padding: `0 ${dimensions.spacing[400]}rem ${dimensions.spacing[500]}rem ${dimensions.spacing[400]}rem`
  },
  [breakpointMq(breakpoints.lg)]: {
    padding: `0 ${dimensions.spacing[200]}rem ${dimensions.spacing[500]}rem ${dimensions.spacing[200]}rem`
  },
  paddingBottom: `${getEmSize(200)}rem`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  width: '100%',
  maxWidth: `${getEmSize(widths.lg)}em`,
  alignSelf: 'center'
})

const StyledButtonsGroup = styled.div({
  display: 'flex',
  flexDirection: 'row',
  [breakpointMq(breakpoints.xxs, breakpoints.md)]: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
    a: {
      marginBottom: `${dimensions.spacing[200]}rem`,
      width: '100%'
    },
    justifyContent: 'stretch',
    flexGrow: 1
  }
})

const StyledContainer = styled(Container)({
  margin: 0,
  [breakpointMq(breakpoints.xxs, breakpoints.md)]: {
    margin: 'auto'
  }
})

const IndexPage = () => {
  return (
    <IndexLayout>
      <Background />
      <StyledPage>
        <StyledContainer>
          <LogoContainer />
          <StyledButtonsGroup>
            <Link to="/invitation-page">
              <SecondaryButton label="View Invitation">View Invitation</SecondaryButton>
            </Link>
            <HSpacer size="300" />
            <Link to="/book-page">
              <PrimaryButton label="Open Commemorative Book">Open Commemorative Book</PrimaryButton>
            </Link>
          </StyledButtonsGroup>
        </StyledContainer>
        <Footer />
      </StyledPage>
    </IndexLayout>
  )
}

export default IndexPage
