import * as React from 'react'
import background from '../images/background.png'
import { heights } from '../styles/variables'

const Background = () => (
  <img
    alt="background"
    src={background}
    style={{
      width: '100vw',
      height: 'auto',
      maxWidth: '900px',
      position: 'absolute',
      backgroundPosition: 'top right',
      top: -heights.header,
      right: 0,
      bottom: 0
    }}
    css={{
      mask: 'radial-gradient(circle at 100% 0%, black 0%, transparent 65%)'
    }}
  />
)

export default Background
