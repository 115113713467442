import styled from '@emotion/styled'
import { transparentize } from 'polished'
import * as React from 'react'
import { breakpointMq, getEmSize } from '../styles/mixins'

import { breakpoints, colors, dimensions, heights, widths } from '../styles/variables'
import ContactButton, { ContactType } from './ContactButton'

const StyledFooter = styled.footer({
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  background: transparentize(0.1, colors.white),
  height: `${heights.footer}rem`,
  justifyContent: 'center',
  alignItems: 'center',
  bottom: 0,
  left: 0,
  width: '100%',
  backdropFilter: 'blur(4px)',
  padding: `0 ${dimensions.containerPadding}rem`,
  [breakpointMq(breakpoints.xxs, breakpoints.sm)]: {
    height: `${heights.footerMobile}rem`
  }
})

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
  maxWidth: `${getEmSize(widths.lg)}em`,
  [breakpointMq(breakpoints.xxs, breakpoints.md)]: {
    flexDirection: 'column',
    justifyContent: 'center'
  }
})

const StyledCopyright = styled.div({
  fontWeight: 300,
  fontSize: `${getEmSize(14)}rem`,
  opacity: 0.7,
  [breakpointMq(breakpoints.xxs, breakpoints.sm)]: {
    fontSize: `${getEmSize(9)}rem`
  },
  [breakpointMq(breakpoints.sm, breakpoints.md)]: {
    fontSize: `${getEmSize(12)}rem`
  }
})

const StyledButtonGroup = styled.div({
  [breakpointMq(breakpoints.xxs, breakpoints.md)]: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: `${dimensions.spacing[100]}rem`,
    a: {
      flexShrink: 0
    },
    maxWidth: `${breakpoints.sm}px`
  },
  [breakpointMq(breakpoints.sm, breakpoints.md)]: {
    marginBottom: `${dimensions.spacing[200]}rem`
  }
})

const Footer = () => (
  <StyledFooter>
    <hr css={{ marginTop: 0, marginBottom: 0, width: '100%' }} />
    <StyledContainer>
      <StyledButtonGroup>
        <ContactButton type={ContactType.web} />
        <ContactButton type={ContactType.facebook} />
        <ContactButton type={ContactType.phone} />
        <ContactButton type={ContactType.email} />
        <ContactButton type={ContactType.map} />
      </StyledButtonGroup>
      <StyledCopyright>©2021 CACC. All Rights Reserved.</StyledCopyright>
    </StyledContainer>
  </StyledFooter>
)

export default Footer
