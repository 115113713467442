import * as React from 'react'
import { dimensions } from '../styles/variables'

export const VSpacer: React.FunctionComponent<{ size: '100' | '200' | '300' | '400' | '500'; className?: string }> = ({
  size,
  className
}) => <div className={className} css={{ height: `${dimensions.spacing[size]}rem` }} />
export const HSpacer: React.FunctionComponent<{ size: '100' | '200' | '300' | '400' | '500'; className?: string }> = ({
  size,
  className
}) => <div className={className} css={{ width: `${dimensions.spacing[size]}rem` }} />
