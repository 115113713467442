import * as React from 'react'
import GatsbyImage from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

export const AnniversaryLogo: React.FC<{ className?: string }> = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "anniversary-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <div className={className}>
      <GatsbyImage alt="30th Anniversary Logo" fluid={data.file.childImageSharp.fluid} loading="eager" fadeIn={false} />
    </div>
  )
}
