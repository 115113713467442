import styled from '@emotion/styled'
import { transparentize } from 'polished'
import * as React from 'react'
import { breakpointMq, getEmSize, hoverMq } from '../styles/mixins'
import { breakpoints, colors, dimensions } from '../styles/variables'

const BaseButton: React.FunctionComponent<{ label: string; className?: string }> = ({ label, className, children }) => (
  <button type="button" aria-label={label} className={className}>
    {children}
  </button>
)

const StyledButton = styled(BaseButton)({
  width: '100%',
  padding: `${dimensions.spacing[200]}rem`,
  fontSize: `${getEmSize(20)}rem`,
  fontVariantCaps: 'titling-caps',
  borderRadius: `${getEmSize(12)}rem`,
  border: `3px solid ${colors.brand}`,
  transition: 'all 200ms ease-out',
  WebkitTapHighlightColor: 'transparent',
  [breakpointMq(breakpoints.xxs, breakpoints.xs)]: {
    padding: `${dimensions.spacing[200]}rem`,
    fontSize: '1rem',
    borderWidth: `${getEmSize(1)}rem`,
    borderRadius: `${getEmSize(8)}rem`
  },
  [breakpointMq(breakpoints.xs, breakpoints.md)]: {
    padding: `${dimensions.spacing[200]}rem`,
    fontSize: '1rem',
    borderWidth: `${getEmSize(2)}rem`,
    borderRadius: `${getEmSize(8)}rem`
  }
})

export const PrimaryButton: React.FunctionComponent<{ label: string; className?: string }> = ({ label, className, children }) => {
  const hoverStyles: React.CSSProperties = {
    backgroundColor: transparentize(0.1, colors.brand),
    borderColor: transparentize(0.1, colors.brand),
    boxShadow: '0px 0px 32px rgba(128, 18, 20, 0.5)'
  }

  return (
    <StyledButton
      className={className}
      label={label}
      css={{
        backgroundColor: colors.brand,
        color: colors.white,
        boxShadow: '0px 0px 24px rgba(128, 18, 20, 0.3)',
        '&:active': {
          ...hoverStyles
        },
        [hoverMq]: {
          '&:hover': {
            ...hoverStyles
          }
        }
      }}
    >
      {children}
    </StyledButton>
  )
}

export const SecondaryButton: React.FunctionComponent<{ label: string; className?: string }> = ({ label, className, children }) => {
  const hoverStyles: React.CSSProperties = { backgroundColor: transparentize(0.9, colors.brand) }
  return (
    <StyledButton
      className={className}
      label={label}
      css={{
        backgroundColor: colors.white,
        color: colors.brand,
        '&:active': {
          ...hoverStyles
        },
        [hoverMq]: {
          '&:hover': {
            ...hoverStyles
          }
        }
      }}
    >
      {children}
    </StyledButton>
  )
}
